body.home {
	header#global-header {
		nav ul.sns li a {
			@include breakpoint(large up) {
				color: $medium-gray;
			}
		}
	}
	
	#hero {
		width: 100%;
		height: 100vh;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		.background {
			position: fixed;
			min-height: 100vh;
			width: 100%;
			top: 0;
			left: 0;
			@include breakpoint(large up) {
				width: calc(100% - 100px);
				left: 100px;
			}
			ul {
				list-style: none;
				margin: 0;
				min-height: 100vh;
				li {
					min-height: 100vh;
					background-repeat: no-repeat;
					background-size: cover;
					background-position: center center;
					&:first-child {
						background-image: url(../images/hero_04.jpg);
					}
					&:nth-child(2) {
						background-image: url(../images/hero_06.jpg);
						background-position: center bottom;
					}
					&:nth-child(3) {
						background-image: url(../images/hero_05.jpg);
					}
					&:nth-child(4) {
						background-image: url(../images/hero_02.jpg);
					}
					&:nth-child(5) {
						background-image: url(../images/hero_03.jpg);
					}
				}
			}
		}
		.logo {
			width: 99px;
			height: auto;
			fill: $white;
			mix-blend-mode: overlay;
		}
		.scroll {
			position: absolute;
			bottom: 0;
			right: 0;
			width: 60px;
			height: 100px;
			&:before, &:after {
				content: "";
				display: block;
				position: absolute;
				left: 50%;
				width: 1px;
			}
			&:before {
				background: $secondary-color;
				height: 100%;
			}
			&:after {
				animation: hero-scroll 2s ease-in-out infinite normal;
				background: $light-gray;
				height: 0;
			}
			span {
				color: $white;
				font-size: 13px;
				letter-spacing: 3px;
				display: block;
				transform: rotate(90deg) translateY(-28px);
				transform-origin: top left;
			}
			@include breakpoint(medium up) {
				right: 30px;
			}
		}
	}
	
	
	
	main {
		z-index: 10;
		@include breakpoint(medium up) {
			padding-top: 150px;
		}
	}
	
	
	
	#section-message {
		h2 {
			font-family: $font-family-serif;
			font-size: 22px;
			text-align: center;
			margin-bottom: 20px;
			letter-spacing: 2px;
			small {
				display: inline-block;
				color: $black;
				font-family: $body-font-family;
				font-size: 14px;
			}
			@include breakpoint(large up) {
				font-size: 32px;
				margin-bottom: 30px;
				small {
					font-size: 18px;
				}
			}
		}
		p {
			line-height: 2;
			@include breakpoint(medium up) {
				line-height: 2;
				text-align: center;
				margin-bottom: 100px;
				span {
					display: block;
				}
			}
		}
		figure {
			@include breakpoint(medium up) {
				width: 70%;
				margin: 0 auto;
			}
		}
	}
	
	
	
	#section-interior {
		padding-top: 100px;
		.right-content {
			position: relative;
			height: 320px;
			figure {
				position: absolute;
			}
		}
		h2 {
			font-size: 46px;
			font-weight: 900;
			letter-spacing: 2px;
			text-align: center;
			line-height: 1;
			margin-bottom: 30px;
		}
		h3 {
			font-size: 15px;
			font-weight: bold;
			line-height: 1.8;
			margin-bottom: 20px;
		}
		figure {
			&.interior {
				width: 230px;
				z-index: 10;
				top: 10px;
				right: 0;
				box-shadow: -5px 5px 0 $white;
			}
			&.house {
				width: 290px;
				left: 0;
				bottom: 20px;
			}
			&.furniture {
				display: none;
				border: 5px solid $white;
			}
		}
		
		.btn-wrap {
			text-align: center;
		}
		@include breakpoint(medium only) {
			h2 {
				margin-bottom: 30px;
			}
			h3 {
				font-size: 22px;
			}
			.right-content {
				height: auto;
				overflow: hidden;
				margin-bottom: 30px;
				figure {
					position: inherit;
					float: left;
					width: 49% !important;
					top: 0;
					box-shadow: none !important;
					&.interior {
						float: right;
					}
				}
			}
		}
		@include breakpoint(large up) {
			padding-top: 250px;
			margin-bottom: 100px;
			.clearfix {
				position: relative;
			}
			.right-content {
				float: right;
				width: 40%;
				position: unset;
			}
			.left-content {
				float: left;
				width: 50%;
			}
			h2 {
				font-size: 100px;
				line-height: 1;
				letter-spacing: 8px;
				margin-bottom: 60px;
			}
			h3 {
				font-size: 20px;
				text-align: left;
				border-bottom: 1px solid;
				padding-bottom: 20px;
				margin-bottom: 20px;
			}
			p {
				margin-bottom: 30px;
			}
			figure {
				&.interior {
					width: 30%;
					top: 0;
					right: -30px;
				}
				&.house {
					width: 46%;
					top: 22%;
					left: auto;
					right: 0;
				}
				&.furniture {
					display: block;
					width: 36%;
					top: 60%;
					right: -20px;
					z-index: 20;
				}
			}
			.btn-wrap {
				text-align: left;
			}
		}
	}
	
	ul.thumbnails {
		&:before {
			display: none;
		}
		&:after {
			width: 32% !important;
		}
		li {
			width: 32%;
		}
	}
	
}
