// About
// ----------

body.page-template-page-about {
	main {
		> figure {
			margin-bottom: 20px;
			@include breakpoint(medium up) {
				margin-bottom: 60px;
			}
		}
		> h3 {
			font-size: 18px;
			text-align: center;
			margin-bottom: 20px;
			&:before {
				content: "“";
				margin-right: 6px;
			}
			&:after {
				content: "”";
				margin-left: 6px;
			}
			@include breakpoint(medium up) {
				font-size: 32px;
			}
		}
		> p {
			@include breakpoint(medium up) {
				text-align: center;
				span {
					display: block;
				}
			}
		}
		> section {
			margin-top: 100px;
			h3 {
				font-weight: 900;
				font-size: 22px;
				letter-spacing: 2px;
				margin-bottom: 20px;
				@include breakpoint(medium up) {
					font-size: 32px;
					margin-bottom: 30px;
				}
			}
		}
	}
	table {
		border-collapse: separate;
		border-spacing: 10px 0;
		margin: 0 -10px;
		width: calc(100% + 20px);
		tbody {
			border: none;
		}
		tr {
			background: none;
		}
		th,td {
			border-top: 1px solid;
			padding: 20px 6px;
		}
		th {
			vertical-align: top;
		}
		@include breakpoint(medium up) {
			width: auto;
			th,td {
				padding: 20px 15px;
			}
		}
		@include breakpoint(large up) {
			th {
				width: 200px;
			}
		}
	}
	#section-vision {
		margin-top: 0;
		overflow: hidden;
		div {
			margin-bottom: 20px;
			@include breakpoint(large up) {
				margin-bottom: 0;
				width: 48%;
				&.left {
					float: left;
				}
				&.right {
					float: right;
				}
			}
		}
		h3 {
			text-align: center;
			margin-bottom: 10px;
			@include breakpoint(large up) {
				margin-bottom: 20px;
			}
		}
		p {
			background: #f9f8f7;
			padding: 15px;
			line-height: 2;
			@include breakpoint(large up) {
				display: flex;
				align-items: center;
				height: 260px;
				padding: 20px;
			}
		}
	}
	#section-photos {
		margin-top: 0;
		@include breakpoint(large up) {
			margin-top: 60px;
		}
		ul {
			list-style: none;
			margin: 0;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			li {
				width: 49%;
				margin-bottom: 5px;
				@include breakpoint(large up) {
					width: 32.5%;
					margin-bottom: 10px;
				}
			}
		}
	}
	#section-history {
		table {
			ul {
				list-style: none;
				margin: 0;
				li {
					&:not(:last-child) {
						margin-bottom: 15px;
					}
				}
			}
		}
	}
	#section-company {
		.clearfix {
			margin-bottom: 30px;
			@include breakpoint(large up) {
				margin-bottom: 60px;
			}
		}
		@include breakpoint(small only) {
			table {
				th,td {
					display: block;
					text-align: left;
					padding: 0;
				}
				th {
					padding: 20px 0 5px;
				}
				td {
					padding: 5px 0 20px;
					border-top: 1px dotted;
				}
			}
		}
		@include breakpoint(medium up) {
			.left, .right {
				width: 48%;
			}
			.left {
				float: left;
			}
			.right {
				float: right;
			}
			table {
				width: 100%;
				th {
					width: 35%;
					font-size: 13px;
					padding: 20px 10px;
				}
			}
		}
		@include breakpoint(large up) {
			table {
				th {
					width: 130px;
				}
			}
		}
	}
	iframe {
		border: none;
		width: calc(100% + 30px);
		margin: 0 -15px;
		height: 200px;
		@include breakpoint(medium up) {
			width: 100%;
			height: 300px;
			margin: 0;
		}
		@include breakpoint(large up) {
			height: 500px;
		}
	}
}



// Works
// ----------

body.page-template-page-works {
	main {
		> section {
			margin-bottom: 100px;
			h3 {
				font-size: 22px;
				font-weight: 900;
				border-bottom: 1px solid;
				padding-bottom: 10px;
				margin-bottom: 20px;
				line-height: 1.1;
				letter-spacing: 1px;
			}
			@include breakpoint(medium up) {
				h3 {
					font-size: 32px;
				}
			}
			@include breakpoint(large up) {
				overflow: hidden;
				h3, > div {
					float: left;
					width: 50%;
				}
				h3 {
					font-size: 100px;
					word-break: break-all;
					span {
						display: inline-block;
						&:first-child:after {
							content: "-";
						}
					}
				}
				&#section-interior {
					h3 {
						span {
							&:first-child:after {
								content: "";
							}
						}
					}
				}
			}
		}
	}
}



// Contact
// ----------

body.page-template-page-contact {
	main {
		.left {
			p.alert {
				color: $alert-color;
			}
		}
		@include breakpoint(medium up) {
			.left {
				margin-bottom: 30px;
				text-align: center;
				p {
					line-height: 1.8;
					span {
						display: block;
					}
				}
			}
			.right {
				width: 600px;
				margin: 0 auto;
			}
		}
		@include breakpoint(large up) {
			.left {
				float: left;
				text-align: left;
				width: 30%;
				p.alert {
					border: 1px solid $alert-color;
					padding: 20px;
					margin-top: 60px;
				}
			}
			.right {
				float: right;
				width: 60%;
			}
		}
	}
	
	.wpcf7 {
		padding-top: 300px;
		margin-top: -300px;
	}
	.screen-reader-response {
		&[role] {
			background: $light-gray;
			padding: 20px 10px;
			margin-bottom: 20px;
			font-weight: bold;
			ul {
				display: none;
			}
			@include breakpoint(medium up) {
				text-align: center;
				padding: 30px;
			}
		}
	}
	.wpcf7-response-output {
		display: none;
	}
	
	form {
		span.required {
			color: $alert-color;
			margin: 0 5px;
		}
		span.wpcf7-form-control-wrap {
			span[role] {
				color: $alert-color;
				font-weight: bold;
				font-size: 14px;
			}
		}
		> p {
			margin-bottom: 15px;
			> span {
				input, textarea {
					margin-bottom: 5px;
				}
			}
		}
		textarea {
			resize: vertical;
		}
		.btn-wrap {
			text-align: center;
			input {
				padding: 20px 70px;
				border: 1px solid $black;
				background: $white;
				color: $black;
				transition: all .5s;
				cursor: pointer;
				&:hover {
					background: $black;
					color: $white;
				}
			}
			.ajax-loader {
				display: none;
			}
		}
		@include breakpoint(medium up) {
			> p:not(.text) {
				display: table;
				width: 100%;
				> label, > span {
					display: table-cell;
				}
				> label {
					font-size: 13px;
					text-align: center;
					width: 30%;
				}
			}
			p.text {
				margin-bottom: 50px;
			}
		}
	}
	
}