body.single {
	article {
		margin-bottom: 60px;
		h3 {
			font-size: 22px;
			font-weight: 900;
			border-bottom: 1px solid;
			padding-bottom: 10px;
			margin-bottom: 20px;
			line-height: 1.1;
			letter-spacing: 1px;
		}
		.n2-section-smartslider {
			margin-bottom: 20px;
		}
		.n2-ss-slider-1 {
			margin-bottom: 15px;
		}
		@include breakpoint(medium up) {
			h3 {
				font-size: 32px;
				margin-bottom: 30px;
			}
		}
		@include breakpoint(large up) {
			margin-bottom: 100px;
			.n2-section-smartslider {
				margin-bottom: 30px;
			}
		}
	}
}