body.category {
	main {
		h3 {
			font-size: 22px;
			font-weight: 900;
			border-bottom: 1px solid;
			padding-bottom: 10px;
			margin-bottom: 20px;
			line-height: 1.1;
			letter-spacing: 1px;
		}
		@include breakpoint(medium up) {
			h3 {
				font-size: 32px;
				margin-bottom: 30px;
			}
		}
	}
}