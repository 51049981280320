@keyframes hero-scroll {
	0% {
		top:auto;
		bottom:0;
		height:100%;
	}
	
	25% {
		top:auto;
		bottom:0;
		height:0;
	}
	
	25.1% {
		top:0;
		bottom:auto;
		height:0;
	}
	
	50% {
		top:0;
		bottom:auto;
		height:100%;
	}
	
	100% {
		top:auto;
		bottom:0;
		height:100%;
	}
}
