header#global-header {
	position: fixed;
	width: 100%;
	height: 47px;
	background: rgba($white, .9);
	z-index: 1000;
	h1 {
		width: 90px;
		line-height: 0;
		position: absolute;
		top: 10px;
		left: 10px;
		a {
			display: block;
		}
	}
	
	nav {
	  background: rgba($black, 0.95);
	  padding: 47px 15px 10px 15px;
	  position: fixed;
	  z-index: 90;
	  top: 0;
	  left: 0;
	  width: 0%;
	  height: 100%;
	  visibility: hidden;
	  opacity: 0;
		-webkit-transition: all .5s;
		transition: all .5s;
		display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
		ul {
		  list-style-type: none;
		  margin: 0;
		  text-align: center;
			li {
			  font-size: 16px;
			  font-weight: 900;
			  margin-bottom: 30px;
			  a {
				  color: $white;
			  }
			}
			&.sns {
				li {
					display: inline-block;
					margin: 20px;
				}
			}
		}
	}
	
	#toggle {
    position: absolute;
    right: 14px;
    top: 14px;
    width: 28px;
    height: 28px;
    cursor: pointer;
    z-index: 100;
    .trigger {
      position: relative;
    }
    span {
      display: block;
      position: absolute;
      height: 1px;
      width: 100%;
      background: $black;
      left: 0;
      -webkit-transition: .35s ease-in-out;
      -moz-transition: .35s ease-in-out;
      transition: .35s ease-in-out;
      &:nth-child(1) {
        top: 0;
      }
      &:nth-child(2) {
        top: 10px;
      }
      &:nth-child(3) {
        top: 20px;
      }
    }
	}
	
	&.open {
		nav {
			visibility: visible;
			opacity: 1;
			width: 100%;
		}
		#toggle {
			span {
				background: $white;
		    &:nth-child(1) {
		      top: 11px;
		      -webkit-transform: rotate(315deg);
		      -moz-transform: rotate(315deg);
		      transform: rotate(315deg);
		    }
		    &:nth-child(2) {
		      width: 0;
		      left: 50%;
		    }
		    &:nth-child(3) {
		      top: 11px;
		      -webkit-transform: rotate(-315deg);
		      -moz-transform: rotate(-315deg);
		      transform: rotate(-315deg);
		    }
			}
		}
	}
	
	
	@include breakpoint(large up) {
		width: 100px;
		height: 100%;
		h1 {
			width: 64px;
			top: 30px;
			left: 18px;
		}
	  #toggle {
	    display: none;
	  }
	  nav {
	    background: none;
	    height: auto;
	    width: 100%;
	    height: 100%;
	    position: static;
	    padding: 0;
	    visibility: visible;
	    opacity: 1;
		  ul {
			  height: auto;
			  transform: rotate(-90deg);
			  display: flex;
			  min-width: 500px;
			  li {
				  font-size: 14px;
				  font-weight: bold;
				  margin: 0 15px;
				  letter-spacing: 1px;
				  a {
					  color: $dark-gray;
					  padding: 16px 0;
					  &:hover {
						  color: $black;
					  }
				  }
			  }
			  &.sns {
				  transform: rotate(0);
				  position: fixed;
				  top: 30px;
				  right: 20px;
				  flex-direction: column;
				  min-width: auto;
				  li {
					  margin: 0;
					  margin-bottom: 10px;
					  font-size: 20px;
					  a {
						  color: $black;
						  padding: 16px;
					  }
				  }
			  }
		  }
	  }
	}
}