body.no-scroll {
  overflow: hidden;
}

.clearfix:after {
	content: " ";
	display: block;
	clear: both;
}

svg.svg {
	display: none;
}

#wrap {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  @include breakpoint(large up) {
	  position: absolute;
	  top: 0;
	  right: 0;
	  width: calc(100% - 100px);
  }
}


.container {
	width: 100%;
	padding: 0 15px;
	@include breakpoint(medium up) {
		padding: 0 30px;
	}
	@include breakpoint(large up) {
		max-width: $global-width;
		margin: 0 auto;
		padding: 0 80px;
	}
}

main {
	background: $white;
	@extend .container;
	padding-top: 80px;
	padding-bottom: 50px;
	@include breakpoint(medium up) {
		padding-bottom: 80px;
	}
}

body.page-template, body.category, body.single {
	main {
		> h2 {
			font-size: 16px;
			font-weight: 900;
			margin-bottom: 20px;
			@include breakpoint(medium up) {
				font-size: 22px;
			}
		}
	}
}


.btn {
	position: relative;
	background: none;
	border: 1px solid $black;
	color: $black;
	transition: all 0.3s ease;
	z-index: 0;
	padding: 20px 50px;
	letter-spacing: 0.1em;
	display: inline-block;
	&:before {
		content: "";
		width: 0;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background: $black;
		transition: all 0.3s ease;
		z-index: -1;
	}
	&:after {
		font-family: FontAwesome;
		content: "\f105";
		position: absolute;
		right: 10px;
	}
	&:hover {
		color: $white;
		&:before {
			width: 100%;
		}
	}
}


.fadein {
  opacity : 0;
  transform : translate(0, 80px);
  transition : all 2s;
  &.right {
	  transform : translate(-80px, 0);
  }
  &.left {
	  transform : translate(80px, 0);
  }
	&.slidein {
	  opacity : 1;
	  transform : translate(0, 0);
	}
}


ul.thumbnails {
	list-style: none;
	margin-left: 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	&:after {
		content: "";
		display: block;
		width: 32%;
	}
	li {
		width: 32%;
		margin-bottom: 10px;
		a {
			display: block;
			transition: all .5s;
			position: relative;
			img {
				width: 100%;
				transition: all .5s;
			}
			&:after {
				position: absolute;
				content: "";
				display: block;
				width: 100%;
				height: 100%;
				background: rgba($primary-color, .15);
				top: 0;
				left: 0;
				opacity: 0;
				transition: all .5s;
			}
			&:hover {
				&:after {
					top: 5px;
					left: -5px;
					opacity: 1;
				}
				img {
					transform: scale(1.05);
					transform: translate(5px,-5px);
				}
			}
		}
	}
	@include breakpoint(large up) {
		&:before {
			content: "";
			display: block;
			width: 23%;
			order: 1;
		}
		&:after {
			width: 23%;
		}
		li {
			width: 23%;
		}
	}
}
