#global-footer {
	// footer fixed
  margin-top: auto;
  
  background: $white;
  font-size: 11px;
  text-align: center;
  z-index: 10;
  .inner {
	  padding: 50px 10px 10px;
  }
}

#back-to-top {
	display: none;
	position: fixed;
	right: 0;
	bottom: 0;
	transform: rotate(90deg) translateY(31px);
	transform-origin: bottom right;
	line-height: 1;
	z-index: 90;
	a {
		display: block;
		background: $black;
		color: $white;
		padding: 10px;
		letter-spacing: 1px;
	}
}